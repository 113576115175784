import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function Modal({
  modalOpen,
  title,
  children,
  small,
  positionClass,
  maxHeight = '80vh',
}) {
  return (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className={`${positionClass ?? 'fixed inset-0'} overflow-y-auto`}>
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full ${
                  small ? 'max-w-md' : 'max-w-3xl'
                } transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                style={{ maxHeight }}
              >
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="font-medium leading-6 text-gray-900"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      style={{
                        flexGrow: 1,
                        height: '1px',
                        backgroundColor: '#CBD5E0', // Tailwind's border-gray-200
                        marginRight: '0.85rem',
                      }}
                    />
                    {title}
                    <span
                      style={{
                        flexGrow: 1,
                        height: '1px',
                        backgroundColor: '#CBD5E0', // Tailwind's border-gray-200
                        marginLeft: '0.85rem',
                      }}
                    />
                  </Dialog.Title>
                )}
                <div
                  className="mt-2 overflow-y-auto"
                  style={{ maxHeight: `calc(${maxHeight} - 4rem)` }}
                >
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
