import React, { startTransition, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import LoadingSkeleton from '../../../components/dashboard/LoadingSkeleton';
import useFetchData from '../../../hooks/useFetchData';
import CreateFoodItem from '../../../components/dashboard/foodItem/CreateFoodItem';
import Modal from '../../../components/Modal';
import Spinner from '../../../components/spinner/Spinner';

function RenderFoodItems({ foodItems, encounteredError, navigate }) {
  if (foodItems?.length > 0) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {foodItems.map((foodItem) => {
          // Extract initials from the food item name
          const initials = foodItem?.name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase())
            .join('');

          // Determine the button based on quantity
          let statusButton = null;
          if (!foodItem?.quantity || foodItem?.quantity === 0) {
            statusButton = (
              <div className="bg-red-500 text-white py-1 px-3 rounded-md text-sm">
                Out of Stock
              </div>
            );
          } else if (foodItem?.quantity < 5) {
            statusButton = (
              <div className="bg-yellow-500 text-white py-1 px-3 rounded-md text-sm">
                Reorder Needed
              </div>
            );
          }

          return (
            <div
              key={foodItem?.id}
              className="flex flex-col h-auto border border-gray-300 rounded-lg overflow-hidden shadow-md"
            >
              {/* Food Item Image or Avatar */}
              {foodItem?.image ? (
                <img
                  src={foodItem?.image}
                  alt={foodItem?.name}
                  className="h-40 w-full object-cover"
                />
              ) : (
                <div className="h-40 w-full flex items-center justify-center bg-gray-200">
                  <span className="text-7xl font-bold text-gray-600">
                    {initials}
                  </span>
                </div>
              )}

              {/* Food Item Details */}
              <div className="p-4 flex flex-col justify-between flex-1">
                <h2 className="text-lg font-semibold text-darkTeal-800">
                  {foodItem?.name}
                </h2>
                <p className="text-base text-gray-700 mt-2">
                  Category: {foodItem?.category?.name}
                </p>
                <p className="text-base text-gray-700 mt-2">
                  Quantity: {foodItem?.quantity || 0} {foodItem?.unit}
                </p>

                {/* Render the status button */}
                <div className="mt-4">{statusButton}</div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="bg-darkTeal-500 text-white w-full py-1 px-3 rounded-md text-base"
                    onClick={() => {
                      startTransition(() =>
                        navigate(`/dashboard/food-items/${foodItem?.id}`)
                      );
                    }}
                  >
                    <InfoIcon fontSize="inherit" className="mr-2" />
                    View More Details
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (encounteredError) {
    return (
      <div className="bg-gray-200 border rounded-md p-4 text-center">
        <p className="text-2xl">
          Apologies! It seems we are currently unable to fetch your saved food
          items. Please try again later.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-gray-200 border rounded-md p-4 text-center">
      <p className="text-2xl">
        You don&apos;t have any food items saved. If you have already created a
        category, you can proceed to add food items. If not, please create a
        category first.
      </p>
    </div>
  );
}

function FoodItemList() {
  const [createFoodItemModalOpen, setCreateFoodItemModalOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [categorySearchParam, setCategorySearchParam] = useState(
    searchParams.get('categoryId')
  );

  const toggleCreateFoodItemModal = () => {
    setCreateFoodItemModalOpen(!createFoodItemModalOpen);
  };

  // get user's food item categories
  const { data: categoriesData, isLoading: isFetchingCategories } =
    useFetchData(
      ['my-categories'],
      '/categories/me',
      {},
      'Could not get categories. Try again later',
      true
    );

  const categoryOptions = categoriesData
    ? categoriesData?.data?.categories?.map((category) => ({
        value: category.id,
        label: `${category.name} Food Items`,
      }))
    : [];

  const {
    data: foodItemsData,
    isLoading: isFetchingFoodItems,
    error: encounteredError,
  } = useFetchData(
    ['my-food-items'],
    '/food-items/me',
    {},
    'Could not get food items. Try again later',
    true
  );

  // Update search params and URL
  const handleCategoryChange = (selectedOption) => {
    const newCategoryId = selectedOption ? selectedOption.value : '';
    setCategorySearchParam(newCategoryId);

    // Update URL search parameters
    const params = new URLSearchParams(searchParams.toString());
    params.set('categoryId', newCategoryId);
    navigate(`?${params.toString()}`, { replace: true });
  };

  const filteredFoodItems = categorySearchParam
    ? foodItemsData?.data?.food_items?.filter(
        (item) => item?.category_id === categorySearchParam
      )
    : foodItemsData?.data?.food_items || [];

  return (
    <div>
      <div className="bg-beige-200 text-darkTeal-700 px-4 py-5 rounded-lg flex justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/dashboard"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
        </Breadcrumbs>
        <div className=" flex space-x-2">
          <button
            type="button"
            className="bg-darkTeal-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
            onClick={toggleCreateFoodItemModal}
          >
            <AddIcon fontSize="inherit" className="mr-2" />
            Create Food Item
          </button>
        </div>
      </div>
      <div className="mt-5">
        {isFetchingCategories ? (
          <Spinner />
        ) : (
          <div className="mb-4">
            <Select
              placeholder="Filter food items by category"
              value={
                categoryOptions?.find(
                  (option) => option.value === categorySearchParam
                ) || null
              }
              isClearable
              className="react-dropdown text-darkTeal-500 rounded-md border border-darkTeal-500"
              classNamePrefix="dropdown"
              options={categoryOptions}
              isSearchable
              onChange={handleCategoryChange}
            />
          </div>
        )}
        {isFetchingFoodItems ? (
          <div className="flex justify-center items-center">
            <LoadingSkeleton />
          </div>
        ) : (
          <RenderFoodItems
            foodItems={filteredFoodItems}
            encounteredError={encounteredError}
            navigate={navigate}
          />
        )}
      </div>
      <Modal
        modalOpen={createFoodItemModalOpen}
        toggleModal={toggleCreateFoodItemModal}
        title="Create Food Item"
      >
        <CreateFoodItem toggleModal={toggleCreateFoodItemModal} />
      </Modal>
    </div>
  );
}

export default FoodItemList;
