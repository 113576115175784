import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { axiosInterceptorV2 } from '../../../utils/Axios/axiosInterceptorV2';
import Spinner from '../../spinner/Spinner';

export default function DeleteFoodItem({ toggleModal, foodItem }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onDeleteFoodItemSuccess = () => {
    toast.success(`Successfully deleted ${foodItem?.name}`);
    toggleModal();
    queryClient.invalidateQueries(['my-food-items']);
    queryClient.invalidateQueries(['my-food-items', foodItem.id]);
    queryClient.invalidateQueries(['my-inventory-analytics']);
    navigate('/dashboard/food-items');
  };

  const onDeleteFoodItemFailure = (err) => {
    toast.error(
      `Couldn't delete ${foodItem?.name}! ${err?.response?.data?.error}`
    );
  };
  const { mutate, isLoading } = useMutation(
    (data) => {
      return axiosInterceptorV2.delete(`/food-items/${foodItem.id}/me`, data);
    },
    {
      onSuccess: onDeleteFoodItemSuccess,
      onError: onDeleteFoodItemFailure,
    }
  );

  return (
    <div>
      <div className="bg-gray-200 rounded-md py-2 px-2 text-base font-semibold w-full mb-3 space-y-1">
        <p>
          <span className="">{`Are you sure you want to delete ${foodItem?.name} ?`}</span>
        </p>
      </div>
      {isLoading ? (
        <div className="w-full flex align-middle justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[2px] border-darkTeal-500 px-4 py-1 rounded-md text-darkTeal-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className="bg-red-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
            type="button"
            onClick={mutate}
          >
            <DeleteIcon fontSize="inherit" className="mr-2" />
            <p>Delete Food Item</p>
          </button>
        </div>
      )}
    </div>
  );
}
