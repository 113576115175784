import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { logout } from '../../../features/auth/authSlice';
import Spinner from '../../spinner/Spinner';
import { axiosInterceptorV2 } from '../../../utils/Axios/axiosInterceptorV2';

// Define the validation schema
const schema = yup.object().shape({
  current_password: yup.string().required('Current password is required'),
  new_password: yup
    .string()
    .required('New password is required')
    .min(6, 'New password must be at least 6 characters'),
  confirm_new_password: yup
    .string()
    .required('Please confirm your new password')
    .oneOf([yup.ref('new_password')], 'Passwords do not match'),
});

function ChangePassword({ toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [shownPassword, setShownPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShownPassword(!shownPassword);
  };

  const dispatch = useDispatch();

  const onChangePasswordSuccess = () => {
    toast.success('Password updated successfully. Please re-login.');
    toggleModal();
    setIsSubmitting(false);
  };

  const onChangePasswordFailure = (err) => {
    toast.error(
      `Password update failed! ${err?.response?.data?.error || 'Please try again later'}`
    );
    setIsSubmitting(false);
  };

  const { mutate } = useMutation(
    (data) => axiosInterceptorV2.put('/users/change-password/me', data),
    {
      onSuccess: () => {
        onChangePasswordSuccess();
        dispatch(logout());
      },
      onError: onChangePasswordFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    mutate(data);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="bg-gray-200 rounded-md py-2 px-2 text-xs w-full mb-3 space-y-1">
        <p className="text-sm font-small">
          Note: You will be logged out once your password is successfully
          changed. You will need to log in again to continue.
        </p>
      </div>
      <form
        className="text-darkTeal-500 space-y-2 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            Current Password
          </label>
          <input
            type={shownPassword ? 'text' : 'password'}
            {...register('current_password')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.current_password
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your new password"
          />
          {errors.current_password && (
            <span className="text-red-500 text-sm">
              {errors.current_password.message}
            </span>
          )}
        </div>
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            New Password
          </label>
          <input
            type={shownPassword ? 'text' : 'password'}
            {...register('new_password')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.new_password
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your new password"
          />
          {errors.new_password && (
            <span className="text-red-500 text-sm">
              {errors.new_password.message}
            </span>
          )}
        </div>
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            Confirm New Password
          </label>
          <input
            type={shownPassword ? 'text' : 'password'}
            {...register('confirm_new_password')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.confirm_new_password
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Confirm your password"
          />
          {errors.confirm_new_password && (
            <span className="text-red-500 text-sm">
              {errors.confirm_new_password.message}
            </span>
          )}
        </div>
        <div className="flex flex-col justify-between space-y-2">
          <div className="flex items-center ">
            <input
              id="show-password"
              onChange={togglePasswordVisibility}
              type="checkbox"
              value="Show Password"
            />
            <label
              htmlFor="show-password"
              className="text-sm text-mine-shaft-500 pl-1"
            >
              Show Passwords
            </label>
          </div>
        </div>
        {isSubmitting ? (
          <div className="grid place-items-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex justify-between mt-8">
            <button
              className="border-[2px] border-darkTeal-500 px-4 py-1 rounded-md text-darkTeal-500 text-sm space-x-2 flex items-center"
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className="bg-darkTeal-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Update</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default ChangePassword;
