import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import { IconButton } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';

export default function MobileHeaderMenu({ logout, height, width }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const loggedInUserId = useGetLoggedInUserId();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="#ffffff" // Set stroke color to white
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className="flex align-middle items-center ">
          {isAuthenticated ? (
            <List>
              <ListItem
                disablePadding
                onClick={() => {
                  handleClose();
                  navigate(`/dashboard/user/${loggedInUserId}`);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Avatar
                      className="mx-auto"
                      src={userDetails?.profile_image}
                      sx={{ width: width || 30, height: height || 30 }}
                    >
                      <div className="text-sm capitalize">
                        {userDetails?.firstname
                          ? userDetails?.firstname[0].toUpperCase()
                          : ''}
                        {userDetails?.lastname
                          ? userDetails?.lastname[0]?.toUpperCase()
                          : ''}
                      </div>
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    style={{ textTransform: 'capitalize' }}
                    primary={`${userDetails?.firstname || ''}
          ${userDetails?.lastname || ''}`}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                onClick={() => {
                  handleClose();
                  navigate(`/dashboard`);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={{ textTransform: 'capitalize' }}
                    primary="dashboard"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={logout}>
                <ListItemButton>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={{ textTransform: 'capitalize' }}
                    primary="logout"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          ) : (
            <List>
              <ListItem
                disablePadding
                onClick={() => {
                  handleClose();
                  navigate(`/register`);
                }}
              >
                <ListItemButton
                  style={{
                    backgroundColor: '#1b2a2a',
                    color: '#ffffff',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    transition: 'background-color 0.3s, color 0.3s',
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems: 'center',
                    justifyContent: 'space-between',
                    margin: '6px',
                  }}
                >
                  <ListItemText
                    style={{
                      textTransform: 'capitalize',
                      marginRight: '8px',
                    }}
                    primary="Register"
                  />
                  <ListItemIcon>
                    <PersonAddIcon sx={{ fontSize: 20, color: '#ffffff' }} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                onClick={() => {
                  handleClose();
                  navigate(`/login`);
                }}
              >
                <ListItemButton
                  style={{
                    backgroundColor: '#6d5841',
                    color: '#ffffff',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    transition: 'background-color 0.3s, color 0.3s',
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems: 'center',
                    justifyContent: 'space-between',
                    margin: '6px',
                  }}
                >
                  <ListItemText
                    style={{ textTransform: 'capitalize', marginRight: '8px' }}
                    primary="Login"
                  />
                  <ListItemIcon>
                    <LoginIcon sx={{ fontSize: 20, color: '#ffffff' }} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          )}
        </div>
      </Popover>
    </div>
  );
}
