import React, {
  startTransition,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import CreateCategory from '../../../components/dashboard/category/CreateCategory';
import Modal from '../../../components/Modal';
import Spinner from '../../../components/spinner/Spinner';
import TableWrapper from '../../../components/dashboard/TableWrapper';
import useFetchData from '../../../hooks/useFetchData';
import EditCategory from '../../../components/dashboard/category/EditCategory';
import DeleteCategory from '../../../components/dashboard/category/DeleteCategory';

function CategoryList() {
  const [createCategoryModalOpen, setCreateCategoryModalOpen] = useState(false);
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [selectedMoreActionOption, setSelectedMoreActionOption] =
    useState(null);

  const navigate = useNavigate();

  const toggleCreateCategoryModal = () => {
    setCreateCategoryModalOpen(!createCategoryModalOpen);
  };

  const toggleEditCategoryModal = useCallback(() => {
    setEditCategoryModalOpen((prev) => !prev);
  }, []);

  const toggleDeleteCategoryModal = useCallback(() => {
    setDeleteCategoryModalOpen((prev) => !prev);
  }, []);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 800) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const { data: categoriesData, isLoading: isFetchingCategories } =
    useFetchData(
      ['my-categories'],
      '/categories/me',
      {},
      'Could not get categories. Try again later',
      true
    );
  const moreActionsOptions = [
    { value: 'viewFoodItems', label: 'View Food Items' },
    { value: 'editCategory', label: 'Edit Category' },
    { value: 'deleteCategory', label: 'Delete Category' },
  ];

  const handleMoreActionsDropdown = (selectedOption, selectedCategory) => {
    if (!selectedOption) return;

    switch (selectedOption.value) {
      case 'viewFoodItems':
        startTransition(() =>
          navigate(`/dashboard/food-items?categoryId=${selectedCategory.id}`)
        );
        break;
      case 'editCategory':
        setCategoryData(selectedCategory);
        toggleEditCategoryModal();
        break;
      case 'deleteCategory':
        setCategoryData(selectedCategory);
        toggleDeleteCategoryModal();
        break;
      default:
        break;
    }
    setSelectedMoreActionOption(null); // Reset selection after action
  };

  const columns = [
    {
      title: 'Category Name',
      field: 'name',
    },
    {
      hidden: isMobileView,
      render: (row) => (
        <button
          className="bg-beige-600 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
          type="button"
          onClick={() => {
            startTransition(() =>
              navigate(`/dashboard/food-items?categoryId=${row.id}`)
            );
          }}
        >
          <RestaurantMenuIcon fontSize="inherit" className="mr-2" />
          Food Items
        </button>
      ),
    },
    {
      hidden: isMobileView,
      render: (row) => (
        <button
          className="bg-darkTeal-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
          type="button"
          onClick={() => {
            setCategoryData(row);
            toggleEditCategoryModal();
          }}
        >
          <EditIcon fontSize="inherit" className="mr-2" />
          Edit Category
        </button>
      ),
    },
    {
      hidden: isMobileView,
      render: (row) => (
        <button
          className="bg-red-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
          type="button"
          onClick={() => {
            setCategoryData(row);
            toggleDeleteCategoryModal();
          }}
        >
          <DeleteIcon fontSize="inherit" className="mr-1" />
          Delete
        </button>
      ),
    },
    {
      hidden: !isMobileView,
      render: (row) => (
        <div className="flex items-center space-x-2">
          <Select
            value={selectedMoreActionOption}
            className="react-dropdown text-darkTeal-500 rounded-md border border-darkTeal-500"
            classNamePrefix="dropdown"
            options={moreActionsOptions}
            isClearable
            isSearchable={false}
            onChange={(selectedOption) => {
              setSelectedMoreActionOption(selectedOption);
              handleMoreActionsDropdown(selectedOption, row);
            }}
            placeholder="More Actions"
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="bg-beige-200 text-darkTeal-700 px-4 py-5 rounded-lg flex justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/dashboard"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
        </Breadcrumbs>
        <div className=" flex space-x-2">
          <button
            type="button"
            className="bg-darkTeal-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
            onClick={toggleCreateCategoryModal}
          >
            <AddIcon fontSize="inherit" className="mr-2" />
            Create Category
          </button>
        </div>
      </div>
      <div className="mt-5">
        {isFetchingCategories ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title="Categories"
            data={categoriesData?.data?.categories || []}
            columns={columns}
          />
        )}
      </div>

      <Modal
        modalOpen={createCategoryModalOpen}
        toggleModal={toggleCreateCategoryModal}
        title="Create Category"
      >
        <CreateCategory toggleModal={toggleCreateCategoryModal} />
      </Modal>
      <Modal
        modalOpen={editCategoryModalOpen}
        toggleModal={toggleEditCategoryModal}
        title="Edit Category"
      >
        <EditCategory
          toggleModal={toggleEditCategoryModal}
          category={categoryData}
        />
      </Modal>
      <Modal
        modalOpen={deleteCategoryModalOpen}
        toggleModal={toggleDeleteCategoryModal}
        title="Delete Category"
      >
        <DeleteCategory
          toggleModal={toggleDeleteCategoryModal}
          category={categoryData}
        />
      </Modal>
    </div>
  );
}

export default CategoryList;
