import React from 'react';
import logo from '../../assets/images/KotaChefLogo.png';
import './AuthSkeleton.css';

function AuthSkeleton({ heading, children }) {
  return (
    <div className="auth-container grid grid-cols-1 md:grid-cols-2">
      {/* Left Column: Displayed only on medium and larger screens */}
      <div className="hidden md:flex flex-col items-center justify-center text-center h-screen p-8">
        <div className="flex items-center mb-4">
          <a href="/" className="flex items-center">
            <img src={logo} alt="Kota Chef Logo" />
          </a>
        </div>
      </div>

      {/* Right Column: Registration Form */}
      <div className="flex items-center justify-center h-screen p-6">
        <div className="bg-white auth-card w-full px-5 py-[5rem] rounded-lg max-w-lg">
          <h4 className="text-4xl text-center font-bold mb-8">{heading}</h4>
          {children}
        </div>
      </div>
    </div>
  );
}

export default AuthSkeleton;
