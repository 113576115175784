// components/Header.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';
import { logout } from '../features/auth/authSlice';
import logo from '../assets/images/KotaChefLogo.png';
import UserMenuPopover from './UserMenuPopover';
import MobileHeaderMenu from './MobileHeaderMenu';

function Header() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const loggedInUserId = useGetLoggedInUserId();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };
  return (
    <div className="fixed top-0 left-0 right-0 z-50 px-2">
      <header className="bg-darkTeal-100 text-white py-4">
        <div className="container mx-auto flex items-center justify-between px-4">
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <img src={logo} alt="Kota Chef Logo" className="h-20" />
            </a>
          </div>

          {/* Medium and Larger Devices Menu */}
          <div className="hidden md:flex space-x-4">
            {isAuthenticated ? (
              <UserMenuPopover
                width={50}
                height={50}
                firstName={userDetails?.firstname}
                lastName={userDetails?.lastname}
                profilePic={userDetails?.profile_image}
                logout={handleLogout}
                visitProfile={() =>
                  navigate(`/dashboard/user/${loggedInUserId}`)
                }
                visitDashboard={() => navigate('/dashboard')}
              />
            ) : (
              <>
                <button
                  className="text-darkTeal-600 bg-gray-100 px-6 py-2 border border-3 border-darkTeal-600 rounded-3xl items-center gap-2 font-medium hidden md:flex"
                  type="button"
                  onClick={() => navigate('/register')}
                >
                  Register
                  <PersonAddIcon sx={{ fontSize: 20 }} />
                </button>
                <button
                  className="text-beige-800 bg-gray-100 px-6 py-2 border border-3 border-beige-600 rounded-3xl items-center gap-2 font-medium hidden md:flex"
                  type="button"
                  onClick={() => navigate('/login')}
                >
                  Login
                  <LoginIcon sx={{ fontSize: 20 }} />
                </button>
              </>
            )}
          </div>
          {/* Smaller Devices Menu */}
          <div className="md:hidden">
            <MobileHeaderMenu width={50} height={50} logout={handleLogout} />
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
