import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditIcon from '@mui/icons-material/Edit';
import EditProfile from '../../../components/dashboard/user/EditProfile';
import Modal from '../../../components/Modal';
import useFetchData from '../../../hooks/useFetchData';
import Spinner from '../../../components/spinner/Spinner';
import ChangePassword from '../../../components/dashboard/user/ChangePassword';
import ChangeEmail from '../../../components/dashboard/user/ChangeEmail';

function UserProfile() {
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const toggleEditProfileModal = () => {
    setEditProfileModalOpen(!editProfileModalOpen);
  };

  const toggleChangeEmailModal = () => {
    setChangeEmailModalOpen(!changeEmailModalOpen);
  };

  const toggleChangePasswordModal = () => {
    setChangePasswordModalOpen(!changePasswordModalOpen);
  };

  const { data: userProfileDetails, isLoading: isFetchingUserProfileDetails } =
    useFetchData(
      ['profile-details'],
      '/users/me',
      {},
      'Could not get profile details. Try again later',
      true
    );

  const initials = [
    `${userProfileDetails?.data?.user?.firstname || ''}`,
    `${userProfileDetails?.data?.user?.lastname || ''}`,
  ]
    .map((word) => word.charAt(0).toUpperCase())
    .join('');

  return (
    <div>
      <div className="bg-beige-200 text-darkTeal-700 px-4 py-5 rounded-lg flex justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/dashboard"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
        </Breadcrumbs>
        {userProfileDetails?.data?.user && (
          <div className=" flex space-x-2">
            <button
              type="button"
              className="bg-darkTeal-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
              onClick={toggleEditProfileModal}
            >
              <EditIcon fontSize="inherit" className="mr-2" />
              Edit Profile
            </button>
          </div>
        )}
      </div>
      {isFetchingUserProfileDetails ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="px-2 py-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          <div className="flex items-center bg-gray-100 border border-gray-300 rounded-md">
            {userProfileDetails?.data?.user?.profile_image ? (
              <img
                src={userProfileDetails?.data?.user?.profile_image}
                alt={userProfileDetails?.data?.user?.firstname}
                className="h-auto max-h-[350px] w-full object-contain"
              />
            ) : (
              <div className="h-40 lg:h-72 w-full flex items-center justify-center bg-gray-300 border rounded-md">
                <span className="text-7xl font-bold text-gray-600">
                  {initials}
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full bg-gray-100 border border-gray-300 rounded-md">
            <div className="flex flex-col p-2">
              <label className="font-bold">Email:</label>
              <div className="flex flex-col bg-gray-200 p-2">
                <span className="text-lg">
                  {userProfileDetails?.data?.user?.email}
                </span>
              </div>
            </div>
            <div className="flex flex-col p-2">
              <label className="font-bold">Full Name:</label>
              <div className="flex flex-col bg-gray-200 p-2">
                <span className="text-lg capitalize">
                  {userProfileDetails?.data?.user?.firstname}{' '}
                  {userProfileDetails?.data?.user?.lastname}
                </span>
              </div>
            </div>
            <div className="flex justify-between space-x-4 p-4">
              <button
                type="button"
                className="border-[2px] border-darkTeal-500 text-darkTeal-500 text-base py-1 px-5 rounded-md cursor-pointer flex items-center"
                onClick={toggleChangeEmailModal}
              >
                <EditIcon fontSize="inherit" className="mr-2" />
                Change Email
              </button>
              <button
                type="button"
                className="bg-darkTeal-500 text-white text-base py-1 px-5 rounded-md cursor-pointer flex items-center"
                onClick={toggleChangePasswordModal}
              >
                <EditIcon fontSize="inherit" className="mr-2" />
                Change Password
              </button>
            </div>
          </div>
          {userProfileDetails?.data?.user?.profile_desc && (
            <div className="md:col-span-2 xl:col-span-1 flex flex-col w-full bg-gray-100 border border-gray-300 rounded-md">
              <div className="flex flex-col p-2">
                <label className="font-bold">Bio:</label>
                <div className="flex flex-col bg-gray-200 p-2">
                  <span className="text-lg overflow-y-auto max-h-[300px]">
                    {userProfileDetails?.data?.user?.profile_desc}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        modalOpen={editProfileModalOpen}
        toggleModal={toggleEditProfileModal}
        title="Edit Profile"
      >
        <EditProfile
          toggleModal={toggleEditProfileModal}
          userDetails={userProfileDetails?.data?.user}
        />
      </Modal>
      <Modal
        modalOpen={changeEmailModalOpen}
        toggleModal={toggleChangeEmailModal}
        title="Change Email"
      >
        <ChangeEmail
          toggleModal={toggleChangeEmailModal}
          currentEmail={userProfileDetails?.data?.user?.email}
        />
      </Modal>
      <Modal
        modalOpen={changePasswordModalOpen}
        toggleModal={toggleChangePasswordModal}
        title="Change Password"
      >
        <ChangePassword toggleModal={toggleChangePasswordModal} />
      </Modal>
    </div>
  );
}

export default UserProfile;
