/* eslint-disable no-param-reassign */
import axios from 'axios';
import { toast } from 'react-hot-toast';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const baseURL =
  process.env.REACT_APP_API_BASE_URL ||
  'https://api.kotachef.metadala.tech/api';

const initialState = {
  isAuthenticated: !!localStorage.getItem('userDetails'),
  userDetails: (() => {
    try {
      return JSON.parse(localStorage.getItem('userDetails') || {});
    } catch {
      return {};
    }
  })(),
  loading: false,
  error: '',
};

// send Login request
export const login = createAsyncThunk('auth/login', async (info) => {
  try {
    const { data } = await axios.post(`${baseURL}/auth/login`, info);

    localStorage.setItem('token', data.token);
    localStorage.setItem('userDetails', JSON.stringify(data.user));

    toast.success('Login Successful!');

    return data;
  } catch (error) {
    localStorage.clear();
    let errorMessage = '';
    if (error?.response?.status === 404) {
      errorMessage = "Account doesn't exist";
    } else if (error?.response?.status === 400) {
      errorMessage = 'Invalid Credentials';
    } else {
      errorMessage = error.message || 'Login failed. Please try again';
    }

    throw Error(errorMessage);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.userDetails = {};
      state.loading = false;
      state.error = '';
    },
    updateUserDetails: (state, action) => {
      localStorage.setItem('userDetails', JSON.stringify(action.payload));
      state.userDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isAuthenticated = false;
        state.error = '';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.userDetails = action.payload.user;
        state.error = '';
      })
      .addCase(login.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { logout, updateUserDetails } = authSlice.actions;

export default authSlice.reducer;
