import React, { useState, useEffect } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '../../spinner/Spinner';
import { axiosInterceptorV2 } from '../../../utils/Axios/axiosInterceptorV2';
import useFetchData from '../../../hooks/useFetchData';
import { fileToBase64 } from '../../../utils/convertToBase64';

const schema = yup.object().shape({
  category: yup
    .object()
    .shape({
      label: yup.string().required('Category is required (from label)'),
      value: yup.string().required('Category is required'),
    })
    .nullable()
    .required('Please select a category'),
  name: yup.string().required('Name is required'),
  description: yup.string().optional(),
  unit: yup.string().required('Unit is required'),
  quantity: yup
    .number()
    .typeError('Quantity must be a number') // Custom error message when the input is not a number
    .min(0, 'Quantity must be at least 0') // Allows 0 and any number greater than 0
    .required('Quantity is required'),
  supplier: yup.string().optional(),
  price_per_unit: yup.string().optional(),
  uploadedImage: yup
    .mixed()
    .required('Please select image')
    .test('fileType', 'Only image files are allowed', (value) => {
      if (!value || value.length === 0) {
        return true;
      }
      const fileType = value[0]?.type;
      return fileType === 'image/jpeg' || fileType === 'image/png';
    })
    .test('imageSize', 'Image size should be less than 4MB', (value) => {
      if (!value || value.length === 0) {
        return true;
      }
      return value[0]?.size <= 4000000; // 4MB
    }),
});

export default function EditFoodItem({ toggleModal, foodItem }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient();

  // get user's food item categories
  const { data: categoriesData, isLoading: isFetchingCategories } =
    useFetchData(
      ['my-categories'],
      '/categories/me',
      {},
      'Could not get categories. Try again later',
      true
    );

  const categoryOptions = categoriesData
    ? categoriesData?.data?.categories?.map((category) => ({
        value: category.id,
        label: category.name,
      }))
    : [];

  const onEditFoodItemSuccess = () => {
    toast.success('Food Item updated successfully.');
    toggleModal();
    setIsSubmitting(false);
  };

  const onEditFoodItemFailure = (err) => {
    toast.error(
      `Food Item update failed! ${err?.response?.data?.error || 'Please try again later'}`
    );
    setIsSubmitting(false);
  };

  const { mutate } = useMutation(
    (data) => axiosInterceptorV2.put(`/food-items/${foodItem.id}/me`, data),
    {
      onSuccess: () => {
        onEditFoodItemSuccess();
        queryClient.invalidateQueries(['my-food-items']);
        queryClient.invalidateQueries(['my-food-items', foodItem.id]);
        queryClient.invalidateQueries(['my-inventory-analytics']);
      },
      onError: onEditFoodItemFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url =
      data?.uploadedImage?.length > 0
        ? await fileToBase64(data.uploadedImage[0])
        : '';

    const requestBody = {
      ...data,
      category_id: data?.category?.value,
      image: base64Url,
    };

    mutate(requestBody);
  };

  useEffect(() => {
    if (foodItem) {
      setValue('category', {
        value: foodItem.category_id,
        label: foodItem.category?.name,
      });
      setValue('name', foodItem.name);
      setValue('description', foodItem.description);
      setValue('unit', foodItem.unit);
      setValue('quantity', foodItem.quantity);
      setValue('supplier', foodItem.supplier);
      setValue('price_per_unit', foodItem.price_per_unit);
    }
  }, [foodItem, setValue]);

  return (
    <div>
      {isFetchingCategories ? (
        <Spinner />
      ) : (
        <form
          className="text-darkTeal-500 space-y-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Category <span className="text-red-500">*</span>
            </label>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Select Category"
                  {...field}
                  isClearable
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={categoryOptions}
                />
              )}
            />
            {errors.category && (
              <span className="text-red-500 text-sm">
                {errors.category.message}
              </span>
            )}
          </div>

          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              {...register('name')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.name
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter name"
            />
            {errors.name && (
              <span className="text-red-500 text-sm">
                {errors.name.message}
              </span>
            )}
          </div>

          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Description
            </label>
            <textarea
              {...register('description')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.description
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter description"
            />
            {errors.description && (
              <span className="text-red-500 text-sm">
                {errors.description.message}
              </span>
            )}
          </div>

          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Unit of Measurement <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              {...register('unit')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.unit
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter unit"
            />
            {errors.unit && (
              <span className="text-red-500 text-sm">
                {errors.unit.message}
              </span>
            )}
          </div>

          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Quantity <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              {...register('quantity')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.quantity
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter quantity"
            />
            {errors.quantity && (
              <span className="text-red-500 text-sm">
                {errors.quantity.message}
              </span>
            )}
          </div>

          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Supplier
            </label>
            <input
              type="text"
              {...register('supplier')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.supplier
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter supplier"
            />
            {errors.supplier && (
              <span className="text-red-500 text-sm">
                {errors.supplier.message}
              </span>
            )}
          </div>

          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Price per Unit
            </label>
            <input
              type="text"
              {...register('price_per_unit')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.price_per_unit
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter price per unit"
            />
            {errors.price_per_unit && (
              <span className="text-red-500 text-sm">
                {errors.price_per_unit.message}
              </span>
            )}
          </div>

          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Upload Image
            </label>
            <input
              type="file"
              {...register('uploadedImage')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.uploadedImage
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              accept="image/*"
            />
            {errors.uploadedImage && (
              <span className="text-red-500 text-sm">
                {errors.uploadedImage.message}
              </span>
            )}
          </div>

          {isSubmitting ? (
            <div className="grid place-items-center">
              <Spinner />
            </div>
          ) : (
            <div className="flex justify-between mt-8">
              <button
                className="border-[2px] border-darkTeal-500 px-4 py-1 rounded-md text-darkTeal-500 text-sm space-x-2 flex items-center"
                type="button"
                onClick={toggleModal}
              >
                <CancelIcon fontSize="inherit" />
                <p>Cancel</p>
              </button>
              <button
                className="bg-darkTeal-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
                type="submit"
              >
                <SaveIcon fontSize="inherit" />
                <p>Update</p>
              </button>
            </div>
          )}
        </form>
      )}
    </div>
  );
}
