import React from 'react';
import { Outlet } from 'react-router';
import TopBar from './TopBar';
import LeftBar from './LeftBar';
import MainContent from './MainContent';

function Layout() {
  return (
    <div className="flex flex-col space-y-20 min-h-screen bg-gray-100 font-roboto_light">
      <TopBar />
      <div className="flex">
        <LeftBar />
        <MainContent full>
          <Outlet />
        </MainContent>
      </div>
    </div>
  );
}

export default Layout;
