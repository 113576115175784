import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import axios from 'axios';
import '../../components/auth/AuthSkeleton.css';
import AuthSkeleton from '../../components/auth/AuthSkeleton';
import Spinner from '../../components/spinner/Spinner';

const baseURL =
  process.env.REACT_APP_API_BASE_URL ||
  'https://api.kotachef.metadala.tech/api';

// Define the validation schema
const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format'),
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
  confirm_password: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
});

function Register() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();
  const authError = useSelector((state) => state.auth.error);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isLoading = useSelector((state) => state.auth.loading);
  const [shownPassword, setShownPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShownPassword(!shownPassword);
  };

  useEffect(() => {
    if (isAuthenticated && !authError && !isLoading) {
      reset({ email: '', password: '' });
      navigate('/');
    }
  }, [isAuthenticated, authError, navigate, reset, isLoading]);

  useEffect(() => {
    if (!isAuthenticated && authError && !isLoading) {
      toast.error(authError);
    }
  }, [isAuthenticated, authError, navigate, reset, isLoading]);

  const handleRegister = (data) => {
    axios
      .post(`${baseURL}/users`, data)
      .then(() => {
        toast.success(
          'Account created successfully. Kindly go ahead and login.'
        );
        navigate('/login');
      })
      .catch((err) => {
        if (Number(err?.response?.status) === 409) {
          toast.error('Account already exists. Kindly go ahead and login.');
          navigate('/login');
        } else {
          toast.error(
            `Account creation failed. ${
              typeof err === 'string' ? err : err?.response?.data?.error
            }. Please try again.`
          );
        }
      });
  };

  return (
    <AuthSkeleton heading="Welcome to KotaChef">
      <form onSubmit={handleSubmit(handleRegister)} className="space-y-4">
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            Email
          </label>
          <input
            type="email"
            {...register('email')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.email
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your email address"
          />
          {errors.email && (
            <span className="text-red-500 text-sm">{errors.email.message}</span>
          )}
        </div>
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            First Name
          </label>
          <input
            type="text"
            {...register('firstname')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.firstname
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your first name"
          />
          {errors.firstname && (
            <span className="text-red-500 text-sm">
              {errors.firstname.message}
            </span>
          )}
        </div>
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            Last Name
          </label>
          <input
            type="text"
            {...register('lastname')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.lastname
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your last name"
          />
          {errors.lastname && (
            <span className="text-red-500 text-sm">
              {errors.lastname.message}
            </span>
          )}
        </div>
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            Password
          </label>
          <input
            type={shownPassword ? 'text' : 'password'}
            {...register('password')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.password
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your password"
          />
          {errors.password && (
            <span className="text-red-500 text-sm">
              {errors.password.message}
            </span>
          )}
        </div>
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            Confirm Password
          </label>
          <input
            type={shownPassword ? 'text' : 'password'}
            {...register('confirm_password')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.confirm_password
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Confirm your password"
          />
          {errors.confirm_password && (
            <span className="text-red-500 text-sm">
              {errors.confirm_password.message}
            </span>
          )}
        </div>
        <div className="flex flex-col justify-between space-y-2">
          <div className="flex items-center ">
            <input
              id="show-password"
              onChange={togglePasswordVisibility}
              type="checkbox"
              value="Show Password"
            />
            <label
              htmlFor="show-password"
              className="text-sm text-mine-shaft-500 pl-1"
            >
              Show Password
            </label>
          </div>
          <small className="text-base">
            Already have an account?{' '}
            <Link className="text-beige-700 font-bold" to="/login">
              Login here
            </Link>
          </small>
        </div>
        <div>
          {isLoading ? (
            <div className="grid place-items-center">
              <Spinner />
            </div>
          ) : (
            <button
              type="submit"
              className="w-full bg-darkTeal-500 text-xl text-white py-2 px-4 flex items-center justify-center gap-2 rounded-md"
            >
              Register
              <PersonAddIcon sx={{ fontSize: 20 }} />
            </button>
          )}
        </div>
      </form>
    </AuthSkeleton>
  );
}

export default Register;
