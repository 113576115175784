import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Home() {
  return (
    <div className="relative min-h-screen bg-gray-200">
      <Header />

      <main className="pt-32">
        <section className="max-w-7xl mx-auto p-6">
          <h1 className="text-4xl text-center text-darkTeal-500 font-bold mb-4">
            Welcome to KotaChef
          </h1>
          <p className="text-lg mb-4">
            Our application is designed specifically for chefs working in Kota
            shops. It aims to streamline the management of food items, helping
            you keep track of your inventory efficiently. With a user-friendly
            interface, you can easily manage your food items by adding,
            updating, or deleting them as needed.
          </p>

          {/* Features */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            <div className="bg-beige-100 p-4 rounded shadow-md ">
              <h2 className="text-xl font-semibold mb-2">Manage Food Items</h2>
              <p>
                Effortlessly add new food items, update existing ones, or remove
                items that are no longer needed. The intuitive dashboard allows
                you to keep your inventory organized and up-to-date.
              </p>
            </div>
            <div className="bg-beige-100 p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold mb-2">
                Real-Time Inventory Levels
              </h2>
              <p>
                The dashboard provides a clear overview of current inventory
                levels, helping you make informed decisions about stock levels
                and order management.
              </p>
            </div>
            <div className="bg-beige-100 p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold mb-2">
                Secure Authentication
              </h2>
              <p>
                The application uses JSON Web Tokens (JWTs) for secure
                authentication, ensuring that only authorized users can access
                and manage the inventory.
              </p>
            </div>
            <div className="bg-beige-100 p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold mb-2">
                User Registration & Login
              </h2>
              <p>
                Easily register and log in to the application. Your credentials
                are securely stored, providing a personalized experience
                tailored to your needs.
              </p>
            </div>
          </div>

          {/* How It Works */}
          <section className="bg-darkTeal-500 text-white p-6 rounded shadow-md mb-6">
            <h2 className="text-2xl font-semibold mb-4">How It Works</h2>
            <p className="text-lg mb-4">
              Get started with our application in just a few steps:
            </p>
            <ul className="list-disc pl-5">
              <li className="mb-2">1. Sign up or log in to your account.</li>
              <li className="mb-2">2. Add your food items to the inventory.</li>
              <li className="mb-2">
                3. Monitor inventory levels in real-time.
              </li>
              <li className="mb-2">4. Update or delete items as needed.</li>
            </ul>
          </section>

          {/* Benefits */}
          <section className="bg-beige-100 p-6 rounded shadow-md mb-6">
            <h2 className="text-2xl font-semibold mb-4">Benefits</h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                Save time with efficient inventory management.
              </li>
              <li className="mb-2">Improve accuracy and reduce waste.</li>
              <li className="mb-2">Access secure and personalized features.</li>
              <li className="mb-2">
                Get real-time insights into your inventory.
              </li>
            </ul>
          </section>

          {/* User Testimonials */}
          <section className="bg-darkTeal-500 text-white p-6 rounded shadow-md mb-6">
            <h2 className="text-2xl font-semibold mb-4">
              What Chefs Are Saying
            </h2>
            <div className="flex flex-col gap-4">
              <blockquote className="border-l-4 border-gray-200 pl-4">
                <p className="italic">
                  &quot;This application has transformed the way I manage my
                  inventory. It&apos;s easy to use and incredibly effective.
                  Highly recommend!&quot;
                </p>
                <footer className="mt-2 text-sm text-gray-200">
                  - Chef Maria
                </footer>
              </blockquote>
              <blockquote className="border-l-4 border-gray-200 pl-4">
                <p className="italic">
                  &quot;The real-time inventory tracking is a game-changer. I
                  can now keep track of everything without the hassle of manual
                  updates.&quot;
                </p>
                <footer className="mt-2 text-sm text-gray-200">
                  - Chef Alex
                </footer>
              </blockquote>
            </div>
          </section>
        </section>
      </main>

      <div className="p-2">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
