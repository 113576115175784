// components/Header.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useGetLoggedInUserId from '../../hooks/useGetLoggedInUserId';
import { logout } from '../../features/auth/authSlice';
import logo from '../../assets/images/KotaChefLogo.png';
import TopBarPopover from './TopBarPopover';
import MobileTopBarPopover from './MobileTopBarPopover';

function TopBar() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const loggedInUserId = useGetLoggedInUserId();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };
  return (
    <div className="fixed top-0 left-0 right-0 z-40 px-2 lg:px-3">
      <header className="bg-darkTeal-100 text-white py-4 border rounded-md">
        <div className="container mx-auto flex items-center justify-between px-4">
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <img src={logo} alt="Kota Chef Logo" className="h-20" />
            </a>
          </div>

          {/* Larger Devices Menu */}
          <div className="hidden lg:flex space-x-4">
            <TopBarPopover
              width={50}
              height={50}
              firstName={userDetails?.firstname}
              lastName={userDetails?.lastname}
              profilePic={userDetails?.profile_image}
              logout={handleLogout}
              visitProfile={() => navigate(`/dashboard/user/${loggedInUserId}`)}
            />
          </div>
          {/* Smaller Devices Menu */}
          <div className="lg:hidden">
            <MobileTopBarPopover
              width={50}
              height={50}
              firstName={userDetails?.firstname}
              lastName={userDetails?.lastname}
              profilePic={userDetails?.profile_image}
              logout={handleLogout}
              visitProfile={() => navigate(`/dashboard/user/${loggedInUserId}`)}
            />
          </div>
        </div>
      </header>
    </div>
  );
}

export default TopBar;
