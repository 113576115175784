import React from 'react';
import CategoryIcon from '@mui/icons-material/Category';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CountUp from 'react-countup';
import LoadingSkeleton from '../../components/dashboard/LoadingSkeleton';
import useFetchData from '../../hooks/useFetchData';

function DashBoardHome() {
  const { data: analyticsData, isLoading: isFetchingAnalyticsData } =
    useFetchData(
      ['my-inventory-analytics'],
      '/inventory/analytics/me',
      {},
      'Could not get analytics. Try again later',
      true
    );

  return (
    <div>
      {' '}
      {isFetchingAnalyticsData ? (
        <LoadingSkeleton />
      ) : (
        <div className=" space-y-5">
          <div className="bg-gray-200 text-2xl px-2 py-5 rounded-lg">
            <p>Dashboard</p>
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 gap-2">
            <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
              <div className=" flex items-center justify-between">
                <div className=" flex flex-col  space-y-2">
                  <p className="font-extralight text-xl">Total Categories</p>
                  <CountUp
                    duration={1}
                    className="font-semibold text-center text-xl"
                    end={analyticsData?.data?.analytics?.total_categories || 0}
                  />
                </div>
                <div className="h-14 w-14 bg-[#8B173B] text-center text-4xl rounded-full flex items-center justify-center">
                  <CategoryIcon
                    fontSize="inherit"
                    className="text-center text-white"
                  />
                </div>
              </div>
            </div>
            <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
              <div className=" flex items-center justify-between">
                <div className=" flex flex-col  space-y-2">
                  <p className="font-extralight text-xl">Total Food Items</p>
                  <CountUp
                    duration={1}
                    className="font-semibold text-center text-xl"
                    end={analyticsData?.data?.analytics?.total_food_items || 0}
                  />
                </div>
                <div className="h-14 w-14 bg-[#00999e] text-center text-4xl rounded-full flex items-center justify-center">
                  <RestaurantMenuIcon
                    fontSize="inherit"
                    className="text-center text-white"
                  />
                </div>
              </div>
            </div>
            <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
              <div className=" flex items-center justify-between">
                <div className=" flex flex-col  space-y-2">
                  <p className="font-extralight text-xl">
                    Below 5 in stock Food Items
                  </p>
                  <CountUp
                    duration={1}
                    className="font-semibold text-center text-xl"
                    end={
                      analyticsData?.data?.analytics?.reorder_food_items || 0
                    }
                  />
                </div>
                <div className="h-14 w-14 bg-[#57C13E] text-center text-4xl rounded-full flex items-center justify-center">
                  <WarningIcon
                    fontSize="inherit"
                    className="text-center text-white"
                  />
                </div>
              </div>
            </div>
            <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
              <div className=" flex items-center justify-between">
                <div className=" flex flex-col  space-y-2">
                  <p className="font-extralight text-xl">
                    Out of stock food items
                  </p>
                  <CountUp
                    duration={1}
                    className="font-semibold text-center text-xl"
                    end={
                      analyticsData?.data?.analytics?.out_of_stock_food_items ||
                      0
                    }
                  />
                </div>
                <div className="h-14 w-14 bg-[#DB60FF] text-center text-4xl rounded-full flex items-center justify-center">
                  <ErrorOutlineIcon
                    fontSize="inherit"
                    className="text-center text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashBoardHome;
