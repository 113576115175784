import React from 'react';
import { NavLink } from 'react-router-dom';
import leftBarData from '../../utils/LeftBarData';

function LeftBar() {
  const activeLink =
    'flex flex-row items-center p-2 mb-1.5 rounded-md cursor-pointer space-x-3 bg-[#b99365] text-white focus:bg-[#b99365]';
  const inactiveLink =
    'flex flex-row items-center p-2 mb-1.5 rounded-md cursor-pointer space-x-3 hover:bg-[#253439]';

  return (
    // Shows for larger devices, hidden for the rest
    <div className="hidden lg:flex h-screen top-32 fixed w-1/5 pl-3">
      <ul className="p-5 w-full space-y-5 bg-darkTeal-100 text-white text-xl max-h-[80%] h-full rounded-lg overflow-auto">
        {leftBarData.map((val) => (
          <NavLink
            key={val.id}
            to={val.link}
            className={({ isActive }) => (isActive ? activeLink : inactiveLink)}
            end
          >
            <div className="mr-1">{val.icon}</div>
            <div>
              <small>{val.title}</small>
            </div>
          </NavLink>
        ))}
      </ul>
    </div>
  );
}

export default LeftBar;
