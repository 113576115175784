import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Spinner from '../../spinner/Spinner';
import { axiosInterceptorV2 } from '../../../utils/Axios/axiosInterceptorV2';

const schema = yup.object().shape({
  name: yup.string().required('Category name is required'),
});

export default function EditCategory({ toggleModal, category }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (category) {
      setValue('name', category?.name);
    }
  }, [category, setValue]);

  const onEditCategorySuccess = () => {
    toast.success('Category updated successfully.');
    toggleModal();
    setIsSubmitting(false);
  };

  const onEditCategoryFailure = (err) => {
    toast.error(
      `Category creation failed! ${err?.response?.data?.error || 'Please try again later'}`
    );
    setIsSubmitting(false);
  };

  const { mutate } = useMutation(
    (data) => axiosInterceptorV2.put(`/categories/${category.id}/me`, data),
    {
      onSuccess: () => {
        onEditCategorySuccess();
        queryClient.invalidateQueries(['my-inventory-analytics']);
        queryClient.invalidateQueries(['my-categories']);
      },
      onError: onEditCategoryFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    mutate(data);
  };
  return (
    <form
      className="text-darkTeal-500 space-y-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <label className="block text-base font-medium text-darkTeal-600">
          Category Name
        </label>
        <input
          type="text"
          {...register('name')}
          className={`mt-1 block w-full p-2 border rounded-md ${
            errors?.name
              ? 'border-red-600 focus:outline-red-600'
              : 'border-darkTeal-200 focus:outline-darkTeal-400'
          }`}
          placeholder="Enter category name"
        />
        {errors.name && (
          <span className="text-red-500 text-sm">{errors.name.message}</span>
        )}
      </div>
      {isSubmitting ? (
        <div className="grid place-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex justify-between mt-8">
          <button
            className="border-[2px] border-darkTeal-500 px-4 py-1 rounded-md text-darkTeal-500 text-sm space-x-2 flex items-center"
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className="bg-darkTeal-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
            type="submit"
          >
            <SaveIcon fontSize="inherit" />
            <p>Update</p>
          </button>
        </div>
      )}
    </form>
  );
}
