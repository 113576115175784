import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import useFetchData from '../../../hooks/useFetchData';
import Spinner from '../../../components/spinner/Spinner';
import Modal from '../../../components/Modal';
import EditFoodItem from '../../../components/dashboard/foodItem/EditFoodItem';
import DeleteFoodItem from '../../../components/dashboard/foodItem/DeleteFoodItem';

export default function FoodItem() {
  const [editFoodItemModalOpen, setEditFoodItemModalOpen] = useState(false);
  const [deleteFoodItemModalOpen, setDeleteFoodItemModalOpen] = useState(false);
  const [selectedMoreActionOption, setSelectedMoreActionOption] =
    useState(null);

  const navigate = useNavigate();
  const { foodItemId } = useParams();

  const toggleEditFoodItemModal = () => {
    setEditFoodItemModalOpen(!editFoodItemModalOpen);
  };

  const toggleDeleteFoodItemModal = () => {
    setDeleteFoodItemModalOpen(!deleteFoodItemModalOpen);
  };

  const moreActionsOptions = [
    { value: 'Edit', label: 'Edit' },
    { value: 'Delete', label: 'Delete' },
  ];

  const handleMoreActionsDropdown = (selectedOption) => {
    if (!selectedOption) return;

    switch (selectedOption.value) {
      case 'Edit':
        toggleEditFoodItemModal();
        break;
      case 'Delete':
        toggleDeleteFoodItemModal();
        break;
      default:
        break;
    }
    setSelectedMoreActionOption(null); // Reset selection after action
  };

  const { data: foodItemData, isLoading: isFetchingFoodItem } = useFetchData(
    ['my-food-items', foodItemId],
    `/food-items/${foodItemId}`,
    {},
    'Could not get food item details. Try again later',
    true
  );

  const foodItem = foodItemData?.data?.food_item;

  const initials = foodItem?.name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');

  let statusButton = null;
  if (!foodItem?.quantity || foodItem?.quantity === 0) {
    statusButton = (
      <div className="bg-red-500 text-white py-1 px-3 rounded-md text-sm">
        Out of Stock
      </div>
    );
  } else if (foodItem?.quantity < 5) {
    statusButton = (
      <div className="bg-yellow-500 text-white py-1 px-3 rounded-md text-sm">
        Reorder Needed
      </div>
    );
  }

  return (
    <div>
      <div className="bg-beige-200 text-darkTeal-700 px-4 py-5 rounded-lg flex justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/dashboard"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-1)}
            to={() => navigate(-1)}
            className="flex items-center"
          >
            <RestaurantMenuIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Food Items</p>
          </NavLink>
        </Breadcrumbs>
        <div className="hidden md:flex space-x-2">
          <button
            type="button"
            className="bg-darkTeal-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
            onClick={toggleEditFoodItemModal}
          >
            <EditIcon fontSize="inherit" className="mr-2" />
            Edit
          </button>
          <button
            type="button"
            className="bg-darkTeal-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
            onClick={toggleDeleteFoodItemModal}
          >
            <DeleteIcon fontSize="inherit" className="mr-2" />
            Delete
          </button>
        </div>
        <div className="flex md:hidden items-center space-x-2">
          <Select
            value={selectedMoreActionOption}
            className="react-dropdown text-darkTeal-500 rounded-md border border-darkTeal-500"
            classNamePrefix="dropdown"
            options={moreActionsOptions}
            isClearable
            isSearchable={false}
            onChange={(selectedOption) => {
              setSelectedMoreActionOption(selectedOption);
              handleMoreActionsDropdown(selectedOption);
            }}
            placeholder="More Actions"
          />
        </div>
      </div>
      <div className="mt-5">
        {isFetchingFoodItem ? (
          <Spinner />
        ) : (
          <div className="mb-4">
            {foodItem ? (
              <div>
                <div className="p-4 text-darkTeal-600 bg-gray-200 rounded-md border text-xl font-semibold">
                  <p>
                    {foodItem?.name
                      ? `${foodItem?.name} Food Item`
                      : 'Food Item Details'}
                  </p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 mt-2 gap-4">
                  <div className="flex flex-col gap-3 bg-gray-200 border rounded-md">
                    <div className="w-full ">
                      {foodItem?.image ? (
                        <img
                          src={foodItem?.image}
                          alt={foodItem?.name}
                          className="h-52 lg:h-72 w-full object-contain border rounded-md"
                        />
                      ) : (
                        <div className="h-40 lg:h-72 w-full flex items-center justify-center bg-gray-300 border rounded-md">
                          <span className="text-7xl font-bold text-gray-600">
                            {initials}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="p-4 flex flex-col h-fit justify-between flex-1">
                      <h2 className="text-lg font-semibold text-darkTeal-800">
                        {foodItem?.name}
                      </h2>
                      <p className="text-base text-gray-700 mt-2">
                        Category: {foodItem?.category?.name}
                      </p>
                      <p className="text-base text-gray-700 mt-2">
                        Quantity: {foodItem?.quantity || 0} {foodItem?.unit}
                      </p>

                      <div className="mt-4">{statusButton}</div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3 p-3 bg-gray-200 border rounded-md">
                    <p className="text-lg text-gray-700">
                      <span className="font-semibold">Price Per Unit:</span>{' '}
                      {foodItem?.price_per_unit || 'Not specified'}
                    </p>
                    <p className="text-lg text-gray-700">
                      <span className="font-semibold">Supplier:</span>{' '}
                      {foodItem?.supplier || 'Not specified'}
                    </p>
                    <div className="flex flex-col text-lg">
                      <label className="font-semibold">Description:</label>
                      <div className="flex flex-col bg-gray-300 p-2">
                        <span className="text-lg overflow-y-auto max-h-[300px]">
                          {foodItem?.description || 'No description available'}
                        </span>
                      </div>
                    </div>
                    <p className="text-lg text-gray-700">
                      <span className="font-semibold">Created On:</span>{' '}
                      {new Date(foodItem?.created_at).toString() ||
                        'Not specified'}
                    </p>
                    <p className="text-lg text-gray-700">
                      <span className="font-semibold">Last Updated:</span>{' '}
                      {new Date(foodItem?.updated_at).toString() ||
                        'Not specified'}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-center text-gray-500">
                No food item details available.
              </p>
            )}
          </div>
        )}
      </div>
      <Modal
        modalOpen={editFoodItemModalOpen}
        toggleModal={toggleEditFoodItemModal}
        title="Edit Food Item"
      >
        <EditFoodItem
          toggleModal={toggleEditFoodItemModal}
          foodItem={foodItem}
        />
      </Modal>
      <Modal
        modalOpen={deleteFoodItemModalOpen}
        toggleModal={toggleDeleteFoodItemModal}
        title="Delete Food Item"
      >
        <DeleteFoodItem
          toggleModal={toggleDeleteFoodItemModal}
          foodItem={foodItem}
        />
      </Modal>
    </div>
  );
}
