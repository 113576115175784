import React from 'react';
import { useNavigate } from 'react-router';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

function Footer() {
  const navigate = useNavigate();

  return (
    <section>
      <section className="bg-beige-200 p-6 rounded shadow-md text-center flex flex-col items-center justify-center">
        <h2 className="text-2xl font-semibold mb-4">Get Started Today!</h2>
        <p className="text-lg mb-4">
          Sign up now to start managing your food inventory with ease. Our
          application is designed to make your life easier and more organized.
        </p>
        <button
          className="text-darkTeal-600 bg-white px-4 py-2 rounded font-semibold flex items-center space-x-2"
          type="button"
          onClick={() => navigate('/register')}
        >
          <span>Register Now</span>
          <PersonAddIcon sx={{ fontSize: 20 }} />
        </button>
      </section>

      {/* Contact Information */}
      <section className="bg-white p-6 rounded shadow-md mb-2">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="text-lg mb-4">
          Have questions or need support? Feel free to reach out to us!
        </p>
        <p className="text-lg mb-2">Email: briansammy1738@gmail.com</p>
        <p className="text-lg">Phone: (+254) 700-000-000</p>
      </section>
    </section>
  );
}

export default Footer;
