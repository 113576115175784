/* eslint-disable no-unused-vars */
import { Route, Routes } from 'react-router-dom';
import './App.css';
import ProtectedRoutes from './utils/PrivateRoutes';
import Home from './pages/Home';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import DashBoardHome from './pages/dashboard/DashBoardHome';
import Layout from './components/dashboard/Layout';
import CategoryList from './pages/dashboard/category/CategoryList';
import FoodItemList from './pages/dashboard/foodItem/FoodItemsList';
import UserProfile from './pages/dashboard/user/UserProfile';
import FoodItem from './pages/dashboard/foodItem/FoodItem';

function App() {
  return (
    <Routes>
      {/* Protected routes under /dashboard */}
      <Route path="/dashboard" element={<ProtectedRoutes />}>
        <Route element={<Layout />}>
          {/* Default route under /dashboard */}
          <Route index element={<DashBoardHome />} />
          <Route path="user/:userId" element={<UserProfile />} />
          <Route path="categories" element={<CategoryList />} />
          <Route path="food-items" element={<FoodItemList />} />
          <Route path="food-items/:foodItemId" element={<FoodItem />} />
        </Route>
      </Route>

      {/* Public routes */}
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
}

export default App;
