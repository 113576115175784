import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { axiosInterceptorV2 } from '../../../utils/Axios/axiosInterceptorV2';
import Spinner from '../../spinner/Spinner';

export default function DeleteCategory({ toggleModal, category }) {
  const queryClient = useQueryClient();

  const onDeleteCategorySuccess = () => {
    toast.success(
      `Successfully deleted ${category?.name} and all its associated food items`
    );
    toggleModal();
    queryClient.invalidateQueries(['my-inventory-analytics']);
    queryClient.invalidateQueries(['my-categories']);
  };

  const onDeleteCategoryFailure = (err) => {
    toast.error(
      `Couldn't delete ${category?.name}! ${err?.response?.data?.error}`
    );
  };
  const { mutate, isLoading } = useMutation(
    (data) => {
      return axiosInterceptorV2.delete(`/categories/${category.id}/me`, data);
    },
    {
      onSuccess: onDeleteCategorySuccess,
      onError: onDeleteCategoryFailure,
    }
  );

  return (
    <div>
      <div className="bg-gray-200 rounded-md py-2 px-2 text-base font-semibold w-full mb-3 space-y-1">
        <p>
          WARNING: Deleting a category will also delete all its associated food
          items!
        </p>
        <p>
          <span className="">{`Are you sure you want to delete ${category?.name} ?`}</span>
        </p>
      </div>
      {isLoading ? (
        <div className="w-full flex align-middle justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[2px] border-darkTeal-500 px-4 py-1 rounded-md text-darkTeal-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className="bg-red-500 text-white text-base py-1 px-5 rounded-md cursor-pointer space-x-30 flex items-center"
            type="button"
            onClick={mutate}
          >
            <DeleteIcon fontSize="inherit" className="mr-2" />
            <p>Delete Category</p>
          </button>
        </div>
      )}
    </div>
  );
}
