import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import axios from 'axios';
import '../../components/auth/AuthSkeleton.css';
import AuthSkeleton from '../../components/auth/AuthSkeleton';
import Spinner from '../../components/spinner/Spinner';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format'),
  otp: yup.string().required('OTP is required'),
  new_password: yup
    .string()
    .required('New Password is required')
    .min(6, 'Password must be at least 6 characters'),
  confirm_password: yup
    .string()
    .required('Please confirm your new password')
    .oneOf([yup.ref('new_password')], 'Passwords do not match'),
});

// Set the base URL for API requests
const baseURL =
  process.env.REACT_APP_API_BASE_URL ||
  'https://api.kotachef.metadala.tech/api';

function ResetPassword() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [shownPassword, setShownPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShownPassword(!shownPassword);
  };

  // Handle form submission
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .put(`${baseURL}/auth/reset-password`, data)
      .then(() => {
        toast.success('Password reset successfully. Go ahead and login.');
        reset();
        navigate('/login');
      })
      .catch((err) => {
        let errMessage = '';
        if (err?.response?.status === 404) {
          errMessage = 'User not found. Kindly confirm your email';
        } else {
          errMessage = `An error occured. ${err?.response?.data?.error || '.'}. Please try again`;
        }
        toast.error(errMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthSkeleton heading="Reset Password">
      <div className="flex flex-col justify-center items-center">
        <div className="bg-gray-200 rounded-md py-2 px-2 text-xs w-full mb-3 space-y-1">
          <p className="text-xs font-small">
            Please enter the email address you used to create your KotaChef
            account, the OTP sent to your email inbox and the new password
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 w-full">
          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Email
            </label>
            <input
              type="email"
              {...register('email')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.email
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter your email address"
            />
            {errors.email && (
              <span className="text-red-500 text-sm">
                {errors.email.message}
              </span>
            )}
          </div>
          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              OTP
            </label>
            <input
              type="text"
              {...register('otp')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.otp
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter OTP"
            />
            {errors.otp && (
              <span className="text-red-500 text-sm">{errors.otp.message}</span>
            )}
          </div>
          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              New Password
            </label>
            <input
              type={shownPassword ? 'text' : 'password'}
              {...register('new_password')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.new_password
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter your new password"
            />
            {errors.new_password && (
              <span className="text-red-500 text-sm">
                {errors.new_password.message}
              </span>
            )}
          </div>
          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Confirm Password
            </label>
            <input
              type={shownPassword ? 'text' : 'password'}
              {...register('confirm_password')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.confirm_password
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Confirm your password"
            />
            {errors.confirm_password && (
              <span className="text-red-500 text-sm">
                {errors.confirm_password.message}
              </span>
            )}
          </div>
          <div className="flex flex-col justify-between space-y-2">
            <div className="flex items-center ">
              <input
                id="show-password"
                onChange={togglePasswordVisibility}
                type="checkbox"
                value="Show Password"
              />
              <label
                htmlFor="show-password"
                className="text-sm text-mine-shaft-500 pl-1"
              >
                Show Password
              </label>
            </div>
          </div>
          <div>
            {isLoading ? (
              <div className="grid place-items-center">
                <Spinner />
              </div>
            ) : (
              <button
                type="submit"
                className="w-full bg-darkTeal-500 text-xl text-white py-2 px-4 flex items-center justify-center gap-2 rounded-md"
              >
                Save New Password
                <SaveIcon sx={{ fontSize: 20 }} />
              </button>
            )}
          </div>
        </form>
      </div>
    </AuthSkeleton>
  );
}

export default ResetPassword;
