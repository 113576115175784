import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import axios from 'axios';
import '../../components/auth/AuthSkeleton.css';
import AuthSkeleton from '../../components/auth/AuthSkeleton';
import Spinner from '../../components/spinner/Spinner';

// Define the validation schema
const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format'),
});

// Set the base URL for API requests
const baseURL =
  process.env.REACT_APP_API_BASE_URL ||
  'https://api.kotachef.metadala.tech/api';

function ForgotPassword() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Handle form submission
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(`${baseURL}/auth/forgot-password-otp`, data)
      .then(() => {
        toast.success(
          'Please check your email for instructions on how to reset your password.'
        );
        reset();
        navigate('/reset-password');
      })
      .catch((err) => {
        let errMessage = '';
        if (err?.response?.status === 404) {
          errMessage = 'User not found. Kindly confirm your email';
        } else {
          errMessage = `An error occured. ${err?.response?.data?.error || '.'} Please try again`;
        }
        toast.error(errMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthSkeleton heading="Forgot Password">
      <div className="flex flex-col justify-center items-center">
        <div className="bg-gray-200 rounded-md py-2 px-2 text-xs w-full mb-3 space-y-1">
          <p className="text-xs font-small">
            Please enter the email address you used to create your KotaChef
            account.
          </p>
          <p>
            Instructions on how to reset your password will be sent to your
            email inbox.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 w-full">
          <div>
            <label className="block text-base font-medium text-darkTeal-600">
              Email
            </label>
            <input
              type="email"
              {...register('email')}
              className={`mt-1 block w-full p-2 border rounded-md ${
                errors?.email
                  ? 'border-red-600 focus:outline-red-600'
                  : 'border-darkTeal-200 focus:outline-darkTeal-400'
              }`}
              placeholder="Enter your email address"
            />
            {errors.email && (
              <span className="text-red-500 text-sm">
                {errors.email.message}
              </span>
            )}
          </div>
          <div>
            {isLoading ? (
              <div className="grid place-items-center">
                <Spinner />
              </div>
            ) : (
              <button
                type="submit"
                className="w-full bg-darkTeal-500 text-xl text-white py-2 px-4 flex items-center justify-center gap-2 rounded-md"
              >
                Request Password Reset OTP
                <MailOutlineIcon sx={{ fontSize: 20 }} />
              </button>
            )}
          </div>
        </form>
        <div className="mt-4 flex gap-2 items-center justify-center">
          <p className="text-base">Remembered your password?</p>
          <button
            type="button"
            className="grid place-items-center"
            onClick={() => navigate('/login')}
          >
            <small className="underline underline-offset-2 text-beige-700 text-base font-bold">
              Login here
            </small>
          </button>
        </div>
      </div>
    </AuthSkeleton>
  );
}

export default ForgotPassword;
