/* eslint-disable react/jsx-filename-extension */
import DashboardIcon from '@mui/icons-material/Dashboard';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'; // Better icon for food items
import CategoryIcon from '@mui/icons-material/Category'; // Better icon for categories

const leftBarData = [
  {
    id: 1,
    title: 'Dashboard',
    icon: <DashboardIcon />,
    link: '/dashboard',
  },
  {
    id: 2,
    title: 'Categories',
    icon: <CategoryIcon />,
    link: '/dashboard/categories',
  },
  {
    id: 3,
    title: 'Food Items',
    icon: <RestaurantMenuIcon />,
    link: '/dashboard/food-items',
  },
];

export default leftBarData;
