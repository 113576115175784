import React, { startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import '../../components/auth/AuthSkeleton.css';
import AuthSkeleton from '../../components/auth/AuthSkeleton';
import Spinner from '../../components/spinner/Spinner';
import { login } from '../../features/auth/authSlice';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format'),
  password: yup.string().required('Password is required'),
});

function Login() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authError = useSelector((state) => state?.auth?.error);
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const isLoading = useSelector((state) => state?.auth?.loading);
  const [shownPassword, setShownPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShownPassword(!shownPassword);
  };

  useEffect(() => {
    if (isAuthenticated && !authError && !isLoading) {
      reset({ email: '', password: '' });
      startTransition(() => navigate('/dashboard'));
    }
  }, [isAuthenticated, authError, navigate, reset, isLoading]);

  useEffect(() => {
    if (!isAuthenticated && authError && !isLoading) {
      toast.error(authError);
    }
  }, [isAuthenticated, authError, navigate, reset, isLoading]);

  const handleLogin = (data) => {
    dispatch(login(data));
  };

  return (
    <AuthSkeleton heading="Welcome Back to KotaChef">
      <form onSubmit={handleSubmit(handleLogin)} className="space-y-4">
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            Email
          </label>
          <input
            type="email"
            {...register('email')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.email
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your email address"
          />
          {errors.email && (
            <span className="text-red-500 text-sm">{errors.email.message}</span>
          )}
        </div>
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            Password
          </label>
          <input
            type={shownPassword ? 'text' : 'password'}
            {...register('password')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.password
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your password"
          />
          {errors.password && (
            <span className="text-red-500 text-sm">
              {errors.password.message}
            </span>
          )}
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex items-center ">
            <input
              id="show-password"
              onChange={togglePasswordVisibility}
              type="checkbox"
              value="Show Password"
            />
            <label
              htmlFor="show-password"
              className="text-sm text-mine-shaft-500 pl-1"
            >
              Show Password
            </label>
          </div>
          <small className="text-beige-700 text-base">
            <Link to="/forgot-password">Forgot Password?</Link>
          </small>
        </div>
        <small className="text-base">
          Don&apos;t have an account ?{' '}
          <Link className="text-beige-700 font-bold" to="/register">
            Create here
          </Link>
        </small>
        <div>
          {isLoading ? (
            <div className="grid place-items-center">
              <Spinner />
            </div>
          ) : (
            <button
              type="submit"
              className="w-full bg-darkTeal-500 text-xl text-white py-2 px-4 flex items-center justify-center gap-2 rounded-md"
            >
              Login
              <LoginIcon sx={{ fontSize: 20 }} />
            </button>
          )}
        </div>
      </form>
    </AuthSkeleton>
  );
}

export default Login;
