import React, { useState, useEffect } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Spinner from '../../spinner/Spinner';
import { axiosInterceptorV2 } from '../../../utils/Axios/axiosInterceptorV2';
import { fileToBase64 } from '../../../utils/convertToBase64';
import { updateUserDetails } from '../../../features/auth/authSlice';

// Define the validation schema
const schema = yup.object().shape({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  uploadedProfileImage: yup
    .mixed()
    .test('fileType', 'Only image files are allowed', (value) => {
      if (!value || value.length === 0) {
        return true;
      }
      const fileType = value[0]?.type;
      return fileType === 'image/jpeg' || fileType === 'image/png';
    })
    .test('imageSize', 'Image size should be less than 4MB', (value) => {
      if (!value || value.length === 0) {
        return true;
      }
      return value[0]?.size <= 4000000; // 4MB
    }),
  profile_desc: yup.string().max(1500, 'Max limit of 1500 characters exceeded'),
});

function EditProfile({ toggleModal, userDetails }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails) {
      setValue('firstname', userDetails.firstname);
      setValue('lastname', userDetails.lastname);
      setValue('profile_desc', userDetails.profile_desc);
    }
  }, [userDetails, setValue]);

  const onProfileUpdateSuccess = () => {
    toast.success('Profile successfully updated!');
    toggleModal();
    setIsSubmitting(false);
  };

  const onProfileUpdateFailure = (err) => {
    toast.error(
      `Profile update failed! ${err?.response?.data?.error || 'Please try again later'}`
    );
    setIsSubmitting(false);
  };

  const { mutate } = useMutation(
    (data) => axiosInterceptorV2.put('/users/me', data),
    {
      onSuccess: (responseData) => {
        dispatch(updateUserDetails(responseData.data.user));
        onProfileUpdateSuccess();
        queryClient.invalidateQueries(['profile-details']);
        queryClient.invalidateQueries(['my-inventory-analytics']);
      },
      onError: onProfileUpdateFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url = data.uploadedProfileImage[0]
      ? await fileToBase64(data.uploadedProfileImage[0])
      : '';

    const requestBody = {
      ...data,
      profile_image: base64Url,
    };

    mutate(requestBody);
  };

  return (
    <form
      className="text-darkTeal-500 space-y-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <label className="block text-base font-medium text-darkTeal-600">
          First Name
        </label>
        <input
          type="text"
          {...register('firstname')}
          className={`mt-1 block w-full p-2 border rounded-md ${
            errors?.firstname
              ? 'border-red-600 focus:outline-red-600'
              : 'border-darkTeal-200 focus:outline-darkTeal-400'
          }`}
          placeholder="Enter your first name"
        />
        {errors.firstname && (
          <span className="text-red-500 text-sm">
            {errors.firstname.message}
          </span>
        )}
      </div>
      <div>
        <label className="block text-base font-medium text-darkTeal-600">
          Last Name
        </label>
        <input
          type="lastname"
          {...register('lastname')}
          className={`mt-1 block w-full p-2 border rounded-md ${
            errors?.lastname
              ? 'border-red-600 focus:outline-red-600'
              : 'border-darkTeal-200 focus:outline-darkTeal-400'
          }`}
          placeholder="Enter your last name"
        />
        {errors.lastname && (
          <span className="text-red-500 text-sm">
            {errors.lastname.message}
          </span>
        )}
      </div>
      <div>
        <label className="block text-base font-medium text-darkTeal-600">
          Profile Image
        </label>
        <input
          {...register('uploadedProfileImage')}
          accept="image/jpg, image/jpeg"
          type="file"
          id="uploadedProfileImage"
          className={`mt-1 block w-full p-2 border rounded-md ${
            errors?.uploadedProfileImage
              ? 'border-red-600 focus:outline-red-600'
              : 'border-darkTeal-200 focus:outline-darkTeal-400'
          }`}
        />
        {errors.uploadedProfileImage && (
          <span className="text-red-500 text-sm">
            {errors.uploadedProfileImage.message}
          </span>
        )}
      </div>
      <div>
        <label className="block text-base font-medium text-darkTeal-600">
          Bio
        </label>
        <textarea
          {...register('profile_desc')}
          className={`mt-1 block w-full p-2 border rounded-md ${
            errors?.profile_desc
              ? 'border-red-600 focus:outline-red-600'
              : 'border-darkTeal-200 focus:outline-darkTeal-400'
          }`}
          placeholder="Enter your Bio"
          rows="5"
        />
        {errors.profile_desc && (
          <span className="text-red-500 text-sm">
            {errors.profile_desc.message}
          </span>
        )}
      </div>
      {isSubmitting ? (
        <div className="grid place-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex justify-between mt-8">
          <button
            className="border-[2px] border-darkTeal-500 px-4 py-1 rounded-md text-darkTeal-500 text-sm space-x-2 flex items-center"
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className="bg-darkTeal-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
            type="submit"
          >
            <SaveIcon fontSize="inherit" />
            <p>Update</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default EditProfile;
