import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { logout } from '../../../features/auth/authSlice';
import Spinner from '../../spinner/Spinner';
import { axiosInterceptorV2 } from '../../../utils/Axios/axiosInterceptorV2';

// Define the validation schema

function ChangeEmail({ toggleModal, currentEmail }) {
  const schema = yup.object().shape({
    new_email: yup
      .string()
      .required('Email is required')
      .email('Invalid email format')
      .test('newEmail', 'Enter a new email', (value) => {
        return value !== currentEmail;
      }),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();

  const onChangeEmailSuccess = () => {
    toast.success('Email updated successfully. Please re-login.');
    toggleModal();
    setIsSubmitting(false);
  };

  const onChangeEmailFailure = (err) => {
    toast.error(
      `Email update failed! ${err?.response?.data?.error || 'Please try again later'}`
    );
    setIsSubmitting(false);
  };

  const { mutate } = useMutation(
    (data) => axiosInterceptorV2.put('/users/me', data),
    {
      onSuccess: () => {
        onChangeEmailSuccess();
        dispatch(logout());
      },
      onError: onChangeEmailFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const requestBody = {
      email: data.new_email,
    };

    mutate(requestBody);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="bg-gray-200 rounded-md py-2 px-2 text-xs w-full mb-3 space-y-1">
        <p className="text-sm font-small">
          Note: You will be logged out once your email is successfully changed.
          You will need to log in again to continue.
        </p>
      </div>
      <form
        className="text-darkTeal-500 space-y-2 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <label className="block text-base font-medium text-darkTeal-600">
            New Email
          </label>
          <input
            type="text"
            {...register('new_email')}
            className={`mt-1 block w-full p-2 border rounded-md ${
              errors?.new_email
                ? 'border-red-600 focus:outline-red-600'
                : 'border-darkTeal-200 focus:outline-darkTeal-400'
            }`}
            placeholder="Enter your new email"
          />
          {errors.new_email && (
            <span className="text-red-500 text-sm">
              {errors.new_email.message}
            </span>
          )}
        </div>
        {isSubmitting ? (
          <div className="grid place-items-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex justify-between mt-8">
            <button
              className="border-[2px] border-darkTeal-500 px-4 py-1 rounded-md text-darkTeal-500 text-sm space-x-2 flex items-center"
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className="bg-darkTeal-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Update</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default ChangeEmail;
