import React from 'react';

function MainContent({ children }) {
  return (
    <div className="absolute px-5 top-32 w-full bg-gray-100 lg:w-4/5 lg:left-[20%]">
      {children}
    </div>
  );
}

export default MainContent;
